<template>
	<div class="grid">

        <div class="col-12">
                    <div class="card">

                        <h5>System Default Settings</h5>

                    <DataTable :value="settings"  class="p-datatable-gridlines"    :loading="loading2"  responsiveLayout="scroll" >

                            <Column field="B4" header="Action" style="min-width:4rem">
                                <template #body="{data}">
                                    <Button v-if="productIdEdit!=data.id" icon="pi pi-pencil" class="p-button-rounded p-button-text  p-button-success mr-2" @click="editProduct(data.id)" />
                                    <Button v-if="productIdEdit==data.id" icon="pi pi-check" class="p-button-rounded p-button-text mr-2" />
                                    <Button v-if="productIdEdit==data.id" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text mr-2"  @click="cancelEdit()"/>
                                </template>
                            </Column>
                            <Column field="name" header="Name" :style="{'min-width':'6rem'}" frozen></Column>
                            <Column field="value" header="Value" :style="{'min-width':'7rem'}">
                                <template #body="{data}">
                                    <div class="formgrid grid p-fluid"  v-if="productIdEdit==data.id">
                                    <div class="col-12">
                                        <InputNumber v-model="data.value"  mode="decimal" :minFractionDigits="2" :maxFractionDigits="2"></InputNumber>
                                        </div>
                                    </div>
                                    <div v-else>
                                        {{parseFloat(data.value).toFixed(2)}}
                                    </div>
                                </template>
                            </Column>
                            <Column field="comment" header="Description" :style="{'min-width':'16rem'}"></Column>
                            
                        
                            
                        
                        </DataTable>

                

                        
                    </div>
        </div>

		
	</div>

</template>

<script>
	import CustomerService from "../../service/CustomerService";
    import SrateService from "../../service/SrateService";
	export default {
		data() {
			return {
				customer2: null,
				loading1: true,
				loading2: true,

                productDialog: false,
                deleteProductDialog: false,
                deleteProductsDialog: false,
                product: {},
                submitted: false,
                productIdEdit:null,
                products:[{"A4":1,"AA4":"4.356282894028471","AB4":"21.32465134099617","AC4":"4.356282894028471","AD4":"0.0928962138579029","AE4":"0.928962138579029","AF4":"0.912189203458832","AG4":"1.0042265003575777","AH4":"0.09203729689874561","AI4":"-1.9919350863032175","AJ4":"1.1278466934799478","AK4":"0.9047401292784374","AL4":"0.9047401292784374","AM4":"0.8866453266928687","AN4":"0.845625","AO4":"1.0042265003575777","AP4":"0.09203729689874561","AQ4":"8.089293300246764","AR4":"#NUM!","AS4":"#VALUE!","AT4":"10.436398091925849","AV4":"10.436398091925849","AW4":"112.34470877241246","AX4":"8.901175773447388","AY4":"11.234470877241247","AZ4":"0.912189203458832","B4":"d_1","BA4":"0.0042265003575776205","C4":"BOSS","D4":2.61,"E4":386,"F4":1.4419,"G4":100,"H4":0.8118,"I4":0.8873,"J4":0.8026,"K4":"0.912189203458832","L4":"112.34470877241246","M4":"8.901175773447388","N4":"4.689408441007062","O4":"0.0928962138579029","P4":"0.845625","Q4":"0.98","S4":"0.9045418685901048","T4":"46.89408441007062","U4":"4.689408441007062","V4":"1.0042265003575777","W4":"0.09203729689874561","X4":"4.13694684062785","Y4":"#NUM!","Z4":"#VALUE!"}]
,
                settings:[
                    {"id":1,"name":"Event Duration in minutes","value":60,"comment":"Total time for every event you created"},
                    {"id":2,"name":"1 to 1 Duration in minutes","value":15,"comment":"....."},
                    {"id":3,"name":"Default time zone","value":"IST","comment":"Time zone you will see on the UI, while creating event"},
                    {"id":4,"name":"T-time for admin/creator event","value":120,"comment":"Admin and creator will get a seperate event, before the actual event."},
                ]
            }
		},
		customerService: null,
		productService: null,
        srateService:null,
		created() {
			this.customerService = new CustomerService();
            this.srateService = new SrateService();
		},
		mounted() {
			
			this.customerService.getCustomersLarge().then(data => this.customer2 = data);
			this.loading2 = false;
		},
		methods: {
			
			formatCurrency(value) {
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			},
			openNew() {
                this.product = {};
                this.submitted = false;
                this.productDialog = true;
            },
            hideDialog() {
                this.productDialog = false;
                this.submitted = false;
            },
            saveProduct() {
                this.submitted = true;
                this.loading2 = true;
                this.productDialog = false;
                console.log(this.product);
                if (this.product.B4 && this.product.B4.trim()) {
                    if (this.product.id) {
                        this.product.inventoryStatus = this.product.inventoryStatus.value ? this.product.inventoryStatus.value: this.product.inventoryStatus;
                        this.products[this.findIndexById(this.product.id)] = this.product;
                        this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
                        }
                        else {
                            this.srateService.getSrateData()
                                .then((res) => {
                                    console.log(res);
                                    this.products.push(res.data);
                                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Created', life: 3000});
                                }).catch(error => {
                                    console.log(error);
                                    this.$toast.add({severity:'error', summary: 'Error', detail: error.message, life: 3000});
                                }).finally(()=> {
                                    this.loading2 = false;
                                    this.submitted = false;
                                    this.product = {};
                                });
                        }
                        
                }
            },
            confirmDeleteProduct(product) {
                this.product = product;
                this.deleteProductDialog = true;
            },
            editProduct(product){
                this.productIdEdit=product;
            },
            cancelEdit(){
                 this.productIdEdit=null;
            }
		}
	}
</script>

<style scoped lang="scss">
	.customer-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.status-qualified {
			background: #C8E6C9;
			color: #256029;
		}

		&.status-unqualified {
			background: #FFCDD2;
			color: #C63737;
		}

		&.status-negotiation {
			background: #FEEDAF;
			color: #8A5340;
		}

		&.status-new {
			background: #B3E5FC;
			color: #23547B;
		}

		&.status-renewal {
			background: #ECCFFF;
			color: #694382;
		}

		&.status-proposal {
			background: #FFD8B2;
			color: #805B36;
		}
	}

	.product-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.status-instock {
			background: #C8E6C9;
			color: #256029;
		}

		&.status-outofstock {
			background: #FFCDD2;
			color: #C63737;
		}

		&.status-lowstock {
			background: #FEEDAF;
			color: #8A5340;
		}
	}

	.order-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.order-delivered {
			background: #C8E6C9;
			color: #256029;
		}

		&.order-cancelled {
			background: #FFCDD2;
			color: #C63737;
		}

		&.order-pending {
			background: #FEEDAF;
			color: #8A5340;
		}

		&.order-returned {
			background: #ECCFFF;
			color: #694382;
		}
	}
</style>